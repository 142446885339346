<template>
    <div style="margin-bottom: 60px;">
        <van-nav-bar title="分销中心" right-text="历史收益" @click-right="onClickRight" />
        <div class="home_person">
            <van-cell-group>
                <van-cell title="分销人员" :value="agentInfo.name" />
                <van-cell title="手机号码" :value="agentInfo.phone" />
                <van-cell title="分销方式" :value="agentInfo.rate_type_label" />
            </van-cell-group>
            <div class="home_logout">
                <van-button round block type="primary" size="small" @click="handleLogout">
                    注销登录
                </van-button>
            </div>
        </div>

        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            {{monthLabel}}分销收益数据汇总
        </van-notice-bar>
        <div class="home_content" v-if="monthData.length">
            <van-collapse v-model="activeName" accordion>
                <van-collapse-item :title="item.settle_day" :name="index+1" v-for="(item,index) in monthData" :key="index">
                    订单交易额：{{ item.order_money/100 }}元，分销提成金额：{{ item.agent_money/100 }}元
                </van-collapse-item>
            </van-collapse>
        </div>
        <van-empty v-if="!monthData.length" description="暂无数据" />
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from "vue-router";
import { AgentBenefitMonth } from "../../api/agent";
export default {
    setup() {
        const agentInfo = ref('');
        const activeName = ref('1');
        const router = useRouter();
        const onClickRight = () => {
            router.push({ name: "History" })
        };
        onBeforeMount(() => {
            if(!localStorage.getItem("agentPhone")){
                router.push({ name: "Login" })
            }else{
                agentInfo.value = JSON.parse(localStorage.getItem("agentInfo"))
                loadData()
            }
        });
        const loadData = ()=>{
            AgentBenefitMonth({phone:localStorage.getItem("agentPhone")}).then((res)=>{
                if(res){
                    monthData.value = res.items
                    monthLabel.value = res.monthLabel
                }
            })
        };
        const monthData = ref([]);
        const monthLabel = ref('');
        const handleLogout = ()=>{
            localStorage.clear();
            router.push({ name: "Login" })
        };
        return {
            activeName,
            onClickRight,
            agentInfo,
            handleLogout,
            monthData,
            monthLabel,
        };
    },
}
</script>
<style>
.home_logout {
    padding: 15px 25px;
}
</style>